import React from "react";

const Logo = ({ color }) => {
  const fill = color || "#3D50FC";

  return (
    <svg
      width="170"
      viewBox="0 0 501 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="47" cy="47" r="47" fill="#ECEDFF" />
      <rect
        x="14.0001"
        y="35.0334"
        width="21.354"
        height="10.677"
        rx="5.33849"
        fill="#A7B0FF"
      />
      <rect
        x="17.6607"
        y="30"
        width="21.354"
        height="10.677"
        rx="5.33849"
        fill="#3D50FC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6608 35.3024C17.6607 35.3144 17.6607 35.3265 17.6607 35.3385C17.6607 38.2869 20.0508 40.677 22.9992 40.677H33.6762C34.2622 40.677 34.8263 40.5825 35.3538 40.408C35.3539 40.396 35.354 40.384 35.354 40.3719C35.354 37.4236 32.9638 35.0334 30.0155 35.0334H19.3385C18.7524 35.0334 18.1884 35.1279 17.6608 35.3024Z"
        fill="#1E32E0"
      />
      <rect
        x="40.9974"
        y="35.0334"
        width="33.5934"
        height="10.677"
        rx="5.33849"
        fill="#A7B0FF"
      />
      <rect
        x="46.7563"
        y="30"
        width="33.5934"
        height="10.677"
        rx="5.33849"
        fill="#3D50FC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.7649 35.0334C46.7592 35.1344 46.7563 35.2361 46.7563 35.3385C46.7563 38.2869 49.1464 40.677 52.0948 40.677H74.5823C74.588 40.576 74.5909 40.4743 74.5909 40.3719C74.5909 37.4236 72.2007 35.0334 69.2524 35.0334H46.7649Z"
        fill="#1E32E0"
      />
      <rect
        x="19.4911"
        y="53.3368"
        width="40.6246"
        height="10.677"
        rx="5.33849"
        fill="#A7B0FF"
      />
      <rect
        x="26.4553"
        y="48.3034"
        width="40.6246"
        height="10.677"
        rx="5.33849"
        fill="#3D50FC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.4638 53.3368C26.4581 53.4378 26.4553 53.5395 26.4553 53.6418C26.4553 56.5902 28.8454 58.9803 31.7937 58.9803H60.1071C60.1128 58.8794 60.1157 58.7777 60.1157 58.6753C60.1157 55.7269 57.7256 53.3368 54.7772 53.3368H26.4638Z"
        fill="#1E32E0"
      />
      <path
        d="M193.2 23.03C193.527 22.0967 194.04 21.3967 194.74 20.93C195.487 20.4633 196.303 20.23 197.19 20.23C198.31 20.23 199.267 20.58 200.06 21.28C200.9 21.98 201.32 22.9133 201.32 24.08C201.32 24.5 201.203 25.1067 200.97 25.9L185.92 67.55C185.547 68.4833 184.917 69.2067 184.03 69.72C183.19 70.2333 182.257 70.49 181.23 70.49C180.203 70.49 179.247 70.2333 178.36 69.72C177.473 69.2067 176.867 68.4833 176.54 67.55L164.92 34.58L153.09 67.55C152.717 68.4833 152.087 69.2067 151.2 69.72C150.36 70.2333 149.427 70.49 148.4 70.49C147.373 70.49 146.417 70.2333 145.53 69.72C144.69 69.2067 144.107 68.4833 143.78 67.55L128.73 25.9C128.497 25.2 128.38 24.5933 128.38 24.08C128.38 22.9133 128.8 21.98 129.64 21.28C130.527 20.58 131.553 20.23 132.72 20.23C133.653 20.23 134.493 20.4633 135.24 20.93C135.987 21.3967 136.523 22.0967 136.85 23.03L148.75 57.26L161 23.24C161.327 22.3067 161.863 21.5833 162.61 21.07C163.357 20.51 164.173 20.23 165.06 20.23C165.947 20.23 166.763 20.51 167.51 21.07C168.303 21.5833 168.863 22.33 169.19 23.31L181.09 57.68L193.2 23.03Z"
        fill="#3D50FC"
      />
      <path
        d="M212.615 70.42C211.355 70.42 210.305 70.07 209.465 69.37C208.671 68.6233 208.275 67.5733 208.275 66.22V39.2C208.275 37.8467 208.671 36.82 209.465 36.12C210.305 35.42 211.355 35.07 212.615 35.07C213.875 35.07 214.925 35.42 215.765 36.12C216.605 36.82 217.025 37.8467 217.025 39.2V66.22C217.025 67.5733 216.605 68.6233 215.765 69.37C214.925 70.07 213.875 70.42 212.615 70.42ZM212.615 28.84C211.028 28.84 209.768 28.42 208.835 27.58C207.901 26.6933 207.435 25.55 207.435 24.15C207.435 22.75 207.901 21.63 208.835 20.79C209.768 19.95 211.028 19.53 212.615 19.53C214.155 19.53 215.391 19.95 216.325 20.79C217.305 21.63 217.795 22.75 217.795 24.15C217.795 25.55 217.328 26.6933 216.395 27.58C215.461 28.42 214.201 28.84 212.615 28.84Z"
        fill="#3D50FC"
      />
      <path
        d="M245.158 35.07C246.371 34.9767 247.328 35.2333 248.028 35.84C248.728 36.4467 249.078 37.3567 249.078 38.57C249.078 39.83 248.775 40.7633 248.168 41.37C247.561 41.9767 246.465 42.3733 244.878 42.56L242.778 42.77C240.025 43.05 237.995 43.9833 236.688 45.57C235.428 47.1567 234.798 49.14 234.798 51.52V66.22C234.798 67.5733 234.378 68.6233 233.538 69.37C232.698 70.07 231.648 70.42 230.388 70.42C229.128 70.42 228.078 70.07 227.238 69.37C226.445 68.6233 226.048 67.5733 226.048 66.22V39.13C226.048 37.8233 226.445 36.82 227.238 36.12C228.078 35.42 229.105 35.07 230.318 35.07C231.531 35.07 232.511 35.42 233.258 36.12C234.005 36.7733 234.378 37.73 234.378 38.99V41.79C235.265 39.7367 236.571 38.15 238.298 37.03C240.071 35.91 242.031 35.28 244.178 35.14L245.158 35.07Z"
        fill="#3D50FC"
      />
      <path
        d="M279.251 60.62C280.045 60.62 280.675 60.9233 281.141 61.53C281.655 62.1367 281.911 62.9533 281.911 63.98C281.911 65.4267 281.048 66.64 279.321 67.62C277.735 68.5067 275.938 69.23 273.931 69.79C271.925 70.3033 270.011 70.56 268.191 70.56C262.685 70.56 258.321 68.9733 255.101 65.8C251.881 62.6267 250.271 58.2867 250.271 52.78C250.271 49.28 250.971 46.1767 252.371 43.47C253.771 40.7633 255.731 38.6633 258.251 37.17C260.818 35.6767 263.711 34.93 266.931 34.93C270.011 34.93 272.695 35.6067 274.981 36.96C277.268 38.3133 279.041 40.2267 280.301 42.7C281.561 45.1733 282.191 48.09 282.191 51.45C282.191 53.4567 281.305 54.46 279.531 54.46H258.881C259.161 57.68 260.071 60.06 261.611 61.6C263.151 63.0933 265.391 63.84 268.331 63.84C269.825 63.84 271.131 63.6533 272.251 63.28C273.418 62.9067 274.725 62.3933 276.171 61.74C277.571 60.9933 278.598 60.62 279.251 60.62ZM267.141 41.09C264.761 41.09 262.848 41.8367 261.401 43.33C260.001 44.8233 259.161 46.97 258.881 49.77H274.701C274.608 46.9233 273.908 44.7767 272.601 43.33C271.295 41.8367 269.475 41.09 267.141 41.09Z"
        fill="#3D50FC"
      />
      <path
        d="M306.434 27.16C304.287 27.3 302.724 27.9533 301.744 29.12C300.764 30.2867 300.274 31.9667 300.274 34.16V35.77H306.224C308.837 35.77 310.144 36.8667 310.144 39.06C310.144 41.2533 308.837 42.35 306.224 42.35H300.274V66.22C300.274 67.5733 299.854 68.6 299.014 69.3C298.22 70 297.194 70.35 295.934 70.35C294.674 70.35 293.624 70 292.784 69.3C291.944 68.6 291.524 67.5733 291.524 66.22V42.35H288.024C285.41 42.35 284.104 41.2533 284.104 39.06C284.104 36.8667 285.41 35.77 288.024 35.77H291.524V35.28C291.524 30.8933 292.667 27.4633 294.954 24.99C297.287 22.47 300.554 21.0467 304.754 20.72L306.574 20.58C308.44 20.44 309.747 20.65 310.494 21.21C311.287 21.7233 311.684 22.54 311.684 23.66C311.684 25.6667 310.54 26.7867 308.254 27.02L306.434 27.16Z"
        fill="#3D50FC"
      />
      <path
        d="M333.342 35.07C334.555 34.9767 335.512 35.2333 336.212 35.84C336.912 36.4467 337.262 37.3567 337.262 38.57C337.262 39.83 336.958 40.7633 336.352 41.37C335.745 41.9767 334.648 42.3733 333.062 42.56L330.962 42.77C328.208 43.05 326.178 43.9833 324.872 45.57C323.612 47.1567 322.982 49.14 322.982 51.52V66.22C322.982 67.5733 322.562 68.6233 321.722 69.37C320.882 70.07 319.832 70.42 318.572 70.42C317.312 70.42 316.262 70.07 315.422 69.37C314.628 68.6233 314.232 67.5733 314.232 66.22V39.13C314.232 37.8233 314.628 36.82 315.422 36.12C316.262 35.42 317.288 35.07 318.502 35.07C319.715 35.07 320.695 35.42 321.442 36.12C322.188 36.7733 322.562 37.73 322.562 38.99V41.79C323.448 39.7367 324.755 38.15 326.482 37.03C328.255 35.91 330.215 35.28 332.362 35.14L333.342 35.07Z"
        fill="#3D50FC"
      />
      <path
        d="M355.732 34.93C360.492 34.93 364.015 36.12 366.302 38.5C368.635 40.8333 369.802 44.4033 369.802 49.21V66.29C369.802 67.55 369.428 68.5533 368.682 69.3C367.935 70 366.908 70.35 365.602 70.35C364.388 70.35 363.385 69.9767 362.592 69.23C361.845 68.4833 361.472 67.5033 361.472 66.29V64.75C360.678 66.57 359.418 67.9933 357.692 69.02C356.012 70.0467 354.052 70.56 351.812 70.56C349.525 70.56 347.448 70.0933 345.582 69.16C343.715 68.2267 342.245 66.9433 341.172 65.31C340.098 63.6767 339.562 61.8567 339.562 59.85C339.562 57.33 340.192 55.3467 341.452 53.9C342.758 52.4533 344.858 51.4033 347.752 50.75C350.645 50.0967 354.635 49.77 359.722 49.77H361.472V48.16C361.472 45.8733 360.982 44.2167 360.002 43.19C359.022 42.1167 357.435 41.58 355.242 41.58C353.888 41.58 352.512 41.79 351.112 42.21C349.712 42.5833 348.055 43.1433 346.142 43.89C344.928 44.4967 344.042 44.8 343.482 44.8C342.642 44.8 341.942 44.4967 341.382 43.89C340.868 43.2833 340.612 42.49 340.612 41.51C340.612 40.7167 340.798 40.04 341.172 39.48C341.592 38.8733 342.268 38.3133 343.202 37.8C344.835 36.9133 346.772 36.2133 349.012 35.7C351.298 35.1867 353.538 34.93 355.732 34.93ZM353.562 64.26C355.895 64.26 357.785 63.49 359.232 61.95C360.725 60.3633 361.472 58.3333 361.472 55.86V54.39H360.212C357.085 54.39 354.658 54.53 352.932 54.81C351.205 55.09 349.968 55.58 349.222 56.28C348.475 56.98 348.102 57.9367 348.102 59.15C348.102 60.6433 348.615 61.88 349.642 62.86C350.715 63.7933 352.022 64.26 353.562 64.26Z"
        fill="#3D50FC"
      />
      <path
        d="M419.365 34.93C423.098 34.93 425.945 36.05 427.905 38.29C429.911 40.4833 430.915 44.0533 430.915 49V66.22C430.915 67.5733 430.495 68.6233 429.655 69.37C428.861 70.07 427.811 70.42 426.505 70.42C425.245 70.42 424.195 70.07 423.355 69.37C422.561 68.6233 422.165 67.5733 422.165 66.22V49C422.165 46.4333 421.698 44.6133 420.765 43.54C419.878 42.42 418.501 41.86 416.635 41.86C414.348 41.86 412.528 42.6533 411.175 44.24C409.821 45.78 409.145 47.9033 409.145 50.61V66.22C409.145 67.5733 408.725 68.6233 407.885 69.37C407.091 70.07 406.065 70.42 404.805 70.42C403.545 70.42 402.495 70.07 401.655 69.37C400.815 68.6233 400.395 67.5733 400.395 66.22V49C400.395 46.4333 399.928 44.6133 398.995 43.54C398.108 42.42 396.731 41.86 394.865 41.86C392.578 41.86 390.758 42.6533 389.405 44.24C388.098 45.78 387.445 47.9033 387.445 50.61V66.22C387.445 67.5733 387.025 68.6233 386.185 69.37C385.345 70.07 384.295 70.42 383.035 70.42C381.775 70.42 380.725 70.07 379.885 69.37C379.091 68.6233 378.695 67.5733 378.695 66.22V39.13C378.695 37.8233 379.115 36.82 379.955 36.12C380.795 35.42 381.845 35.07 383.105 35.07C384.318 35.07 385.298 35.42 386.045 36.12C386.838 36.7733 387.235 37.73 387.235 38.99V40.67C388.308 38.8033 389.731 37.38 391.505 36.4C393.325 35.42 395.401 34.93 397.735 34.93C402.868 34.93 406.298 37.0767 408.025 41.37C409.051 39.41 410.568 37.8467 412.575 36.68C414.581 35.5133 416.845 34.93 419.365 34.93Z"
        fill="#3D50FC"
      />
      <path
        d="M466.898 60.62C467.691 60.62 468.321 60.9233 468.788 61.53C469.301 62.1367 469.558 62.9533 469.558 63.98C469.558 65.4267 468.694 66.64 466.968 67.62C465.381 68.5067 463.584 69.23 461.578 69.79C459.571 70.3033 457.658 70.56 455.838 70.56C450.331 70.56 445.968 68.9733 442.748 65.8C439.528 62.6267 437.918 58.2867 437.918 52.78C437.918 49.28 438.618 46.1767 440.018 43.47C441.418 40.7633 443.378 38.6633 445.898 37.17C448.464 35.6767 451.358 34.93 454.578 34.93C457.658 34.93 460.341 35.6067 462.628 36.96C464.914 38.3133 466.688 40.2267 467.948 42.7C469.208 45.1733 469.838 48.09 469.838 51.45C469.838 53.4567 468.951 54.46 467.178 54.46H446.528C446.808 57.68 447.718 60.06 449.258 61.6C450.798 63.0933 453.038 63.84 455.978 63.84C457.471 63.84 458.778 63.6533 459.898 63.28C461.064 62.9067 462.371 62.3933 463.818 61.74C465.218 60.9933 466.244 60.62 466.898 60.62ZM454.788 41.09C452.408 41.09 450.494 41.8367 449.048 43.33C447.648 44.8233 446.808 46.97 446.528 49.77H462.348C462.254 46.9233 461.554 44.7767 460.248 43.33C458.941 41.8367 457.121 41.09 454.788 41.09Z"
        fill="#3D50FC"
      />
      <path
        d="M496.105 35.07C497.319 34.9767 498.275 35.2333 498.975 35.84C499.675 36.4467 500.025 37.3567 500.025 38.57C500.025 39.83 499.722 40.7633 499.115 41.37C498.509 41.9767 497.412 42.3733 495.825 42.56L493.725 42.77C490.972 43.05 488.942 43.9833 487.635 45.57C486.375 47.1567 485.745 49.14 485.745 51.52V66.22C485.745 67.5733 485.325 68.6233 484.485 69.37C483.645 70.07 482.595 70.42 481.335 70.42C480.075 70.42 479.025 70.07 478.185 69.37C477.392 68.6233 476.995 67.5733 476.995 66.22V39.13C476.995 37.8233 477.392 36.82 478.185 36.12C479.025 35.42 480.052 35.07 481.265 35.07C482.479 35.07 483.459 35.42 484.205 36.12C484.952 36.7733 485.325 37.73 485.325 38.99V41.79C486.212 39.7367 487.519 38.15 489.245 37.03C491.019 35.91 492.979 35.28 495.125 35.14L496.105 35.07Z"
        fill="#3D50FC"
      />
    </svg>
  );
};

export default Logo;
